import GolfErpAPI from "@/api/v2/GolfErpAPI";
export const ROOT_PATH = '/stock-cart-mng';

class StockCartMng {

    /** 카트 수리내역 조회 */
    async getCartRepairHdr(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/repair/list` ,{params} );
        return data;
    }
    /** 카트 수리내역 조회 VO */
    async getCartRepairHdrVO(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/repair/listVO` ,{params} );
        return data;
    }
    /** 카트 수리내역 상세 조회 */
    async getCartRepairDetail(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/repair/Detail` ,{params} );
        return data;
    }
    /** 카트 수리내역 상세 등록 */
    async postCartRepairDetail(params) {
        const {data} = await  GolfErpAPI.http.post(`${ROOT_PATH}/repair/Detail` ,params);
        return data;
    }
    /** 카트 수리내역 상세 수정 */
    async patchCartRepairDetail(params) {
        const {data} = await  GolfErpAPI.http.post(`${ROOT_PATH}/repair/Detail` ,params);
        return data;
    }
    /** 카트 수리내역 상세 삭제 */
    async deleteCartRepairDetail(params) {
        const {data} = await GolfErpAPI.http.delete(`${ROOT_PATH}/repair/Detail` ,{params} );
        return data;
    }
    /** 카트 출고 품목 조회 */
    async getCartRepairDtlList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/repair/DtlList` ,{params} );
        return data;
    }
    /** 카트 정보 조회 */
    async getCartList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/cart/List` ,{params} );
        return data;
    }
    /** 카트 수리이력 조회 */
    async getCartRepairList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/cart/repair` ,{params} );
        return data;
    }

}

export default new StockCartMng();